<template>
  <div style="height:100%;">
    <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">
      全选
    </a-checkbox>
    <a-button type="primary" style="margin-bottom:8px;margin-left:18px" @click="set_isViewed"
      :disabled="is_viewedArray.length==0">
      批量已读
    </a-button>

    <div
      style="height:calc(100% - 40px);font-family: sans-serif;font-size: 13px;display: flex;    width: 100%;overflow: auto;">

      <div style="width:420px;border:1px solid #ddd;max-height: 100%;background:#fff;    min-width: 400px;
    overflow: auto;">

        <a-checkbox-group v-model="is_viewedArray" @change="onChange" style="    width: 100%;overflow: hidden;">
          <div class="emailItem" v-for="item in email_list" @click="clickItem(item)" :class="selectId==item.id?'bg':''">
            <div style="display: flex;align-items: center;justify-content: space-between;">
              <span style="height:10px;width:10px;display: block;background:#dfe9f7;border-radius:50%;"
                :style="{background:item.is_viewed==0?'#5790de':'#dfe9f7'}"></span>
              <span style="overflow:hidden;text-overflow:ellipsis;white-space: nowrap;flex-grow: 1;margin: 0 10px;"
                :style="{fontWeight:item.is_viewed==0?'bold':'400'}">{{item.sender_email}}</span>

              <span style="    white-space: nowrap;">{{item.email_time}}</span>
            </div>

            <div style="margin-top:4px;display: flex;align-items: center;">
              <a-checkbox :value="item.id" @click.stop="aaa" v-if="item.is_viewed==0">
              </a-checkbox>
              <span
                style="overflow:hidden;text-overflow:ellipsis;white-space: nowrap;margin-left: 4px;">{{item.email_subject}}</span>

            </div>

          </div>
        </a-checkbox-group>
      </div>

      <div
        style="flex-grow: 1;padding:12px;max-height: 100%; overflow: auto;width: calc( 100% - 420px);    min-width: 480px;"
        v-if="Object.keys(emailContent).length > 0">
        <span style="    font-size: 16px;font-weight: 600;">{{emailContent.email_subject}}</span>
        <div style="border:1px solid #ddd;margin-top:10px;background:#fff;padding:10px;">
          <span style="font-weight: 600;display: block;margin-bottom:4px">{{emailContent.sender_email}}</span>

          <div style="display: flex;align-items: center;justify-content: space-between;">
            <span>发给 {{emailContent.receiver_email}}</span>
            <div style="display: flex;align-items: center;">
              <span style="margin-right:16px;"> {{emailContent.email_time}}</span>
              <a-dropdown v-if="emailContent.attachment_url.length>0">
                <a class="ant-dropdown-link" style='display: block;'>
                  下载附件 <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item v-for="(i,z) in emailContent.attachment_url" :key="z">
                    <a :href="i" target='_blank'>{{i}}</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </div>

          <div style="border-top:1px solid #ddd;padding:10px 0;margin-top: 10px;width: 100%;
    overflow: auto;" v-html="emailContent.message">
            <!-- {{emailContent.message}} -->
          </div>
        </div>
      </div>





    </div>
  </div>
</template>
<script>

  import { getCookie } from '@/static/js/cookie';
  import { myMixin } from "@/static/js/mixin.js"
  import { getEmailList, maker_seen } from "@/axios/home.js"
  import moment from 'moment';

  export default {
    inject: ['app'],
    mixins: [myMixin],
    data() {

      return {
        email_config_id: '',
        email_list: [],
        emailContent: {},
        selectId: '',
        is_viewedArray: [],

        indeterminate: false,
        checkAll: false,
      }

    },
    created() {
      // this.token = JSON.parse(getCookie("token"))
      this.email_config_id = this.$route.params.id;
      this.fetch();
    },
    mounted() {




    },
    methods: {
      //全选
      onCheckAllChange(e) {
        if (e.target.checked) {
          this.is_viewedArray = []
          this.email_list.map((item) => {
            if (item.is_viewed == 0) {
              this.is_viewedArray.push(item.id)
            }

          })
        } else {
          this.is_viewedArray = []
        }
        console.log(this.is_viewedArray, this.email_list);
        this.checkAll = e.target.checked;
        this.indeterminate = false;
        // console.log(e.target.checked, this.is_viewedArray)

      },
      //批量设置
      set_isViewed() {

        maker_seen({ ids: this.is_viewedArray.join(',') }).then((res) => {
          if (res.code == 200) {
            this.app.set_isViewed_num(this.email_config_id, this.is_viewedArray.length)
            this.is_viewedArray = [];
            this.checkAll = false;
            this.indeterminate = false;
            this.fetch();

          }
        })
      },
      //空函数，设置click.stop防止冒泡
      aaa() { },
      //单选
      onChange(checkedValues) {
        this.is_viewedArray = checkedValues;
        this.checkAll = this.is_viewedArray.length === this.email_list.length;
        this.indeterminate = !!this.is_viewedArray.length && this.is_viewedArray.length < this.email_list.length;

      },
      async fetch(params = {}) {

        let data = await getEmailList({ email_config_id: this.email_config_id })
        this.email_list = data.data.list
        console.log(this.email_list, 'data')
      },

      clickItem(item) {

        let that = this;
        if (item.is_viewed == 0) {
          maker_seen({ ids: item.id }).then((res) => {
            if (res.code == 200) {

              this.is_viewedArray = this.is_viewedArray.filter(i => i != item.id)
              this.indeterminate = !!this.is_viewedArray.length && this.is_viewedArray.length < this.email_list.length;
              this.app.set_isViewed_num(this.email_config_id, 1)
              item.is_viewed = 1;

            }
          })
        }
        that.emailContent = {};


        that.selectId = item.id
        console.log(that.emailContent, '邮件详情')
        this.$nextTick(() => {
          that.emailContent = item;
        })
      },

    },
    watch: {
      $route(to) {
        this.email_config_id = '';
        this.email_list = [];
        this.emailContent = {};
        this.selectId = '';
        this.email_config_id = this.$route.params.id;
        this.fetch();
      }
    },
  };
</script>
<style lang="scss" scoped>
  @import "@/static/css/index.scss";

  .emailItem {
    border-bottom: 1px solid #ddd;
    padding: 10px;
    border-right: 1px solid #ddd;
  }

  .emailItem:focus {
    background: #000;
  }

  .bg {
    background: #94a6c4;
    color: #fff;
  }
</style>